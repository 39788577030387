/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'

// Images
import Email from 'img/mail.inline.svg'

const Section = styled.section`
  font-family: ${({ theme }) => theme.font.family.primary};
  position: relative;
  z-index: 2;
`

const Member = styled.div`
  & h2 {
    color: ${({ theme }) => theme.color.primary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-size: 22px;
  }

  & span {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  @media (min-width: 992px) {
    padding: 1rem 1rem 1rem 6rem;
  }
`

const Image = styled(Plaatjie)`
  position: absolute !important;
  width: 186px;
  height: 186px;
  border-radius: 50%;

  @media (min-width: 992px) {
    top: 10%;
    left: -113px;
  }

  @media (max-width: 991px) {
    width: 95px;
    height: 95px;
    top: -20px;
    right: -20px;
  }

  @media (max-width: 575px) {
    top: -80px;
    right: -5px;
  }
`

const SvgWrapper = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.color.secondary};
  border-radius: 50%;
  padding: 0.5rem;

  & svg {
    width: 30px;
    height: 30px;
  }

  &:hover {
    opacity: 0.8;
  }
  @media (min-width: 992px) {
    top: 150px;
    left: 15px;
  }
  @media (max-width: 991px) {
    top: 35px;
    right: 35px;
  }
  @media (max-width: 575px) {
    top: -15px;
    right: -10px;

    & svg {
      width: 18px;
      height: 18px;
    }
  }
`

interface TeamProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Team
}

const Team: React.FC<TeamProps> = ({ fields }) => (
  <Section id="team">
    <div className="container">
      <div className="row">
        <ParseContent content={fields.description} className="mb-5 pb-5" />
        {fields.teammembers?.map((member, index) => (
          <div
            className={`col-md-6 mb-5 mb-md-${member?.description ? '4' : '5'}`}
            key={index}
          >
            <div className="mx-lg-5">
              <div className="mx-md-3">
                <BlockPrimary className="position-relative">
                  <div className="position-relative">
                    <Image image={member?.image} alt="" />
                    {/* <a
                      href={member?.email?.url}
                      className="text-hidden"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {member?.email?.title}
                      <SvgWrapper>
                        <Email />
                      </SvgWrapper>
                    </a> */}
                  </div>

                  <Member>
                    <h2 className="mb-0">{member?.name}</h2>
                    <span>{member?.jobtitle}</span>
                    <ParseContent
                      content={member?.description}
                      className="mt-4"
                    />
                  </Member>
                </BlockPrimary>
              </div>
            </div>
          </div>
        ))}
        <ParseContent
          content={fields.seconddescription}
          className="mt-5 mb-5 pb-5"
        />
      </div>
    </div>
  </Section>
)

export default Team
